import { useEffect } from 'react';

export const useSpacebarListener = (
  ref: HTMLDivElement | null,
  onSpacebarPress: () => void
) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        onSpacebarPress();
      }
    };

    const handleClick = () => {
      if (ref) {
        ref.focus(); // Focus the element when clicked
      }
    };

    const divElement = ref;

    if (divElement) {
      divElement.addEventListener('keydown', handleKeyDown);
      divElement.addEventListener('click', handleClick); // Make div focusable on click
    }

    return () => {
      if (divElement) {
        divElement.removeEventListener('keydown', handleKeyDown);
        divElement.removeEventListener('click', handleClick);
      }
    };
  }, [onSpacebarPress, ref]);
};
