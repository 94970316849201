import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { APIClient } from '@agerpoint/api';
import {
  CloudButton,
  CloudButtonIconToggle,
} from '@agerpoint/cloud/components';
import {
  EffectNames,
  ILine3d,
  IMultiPoint2d,
  IMultiPoint3d,
  IPoint2d,
  IPoint3d,
  IPotreeViewerController,
  MaterialType,
} from '@agerpoint/types';
import {
  APIUtils,
  useActionListener,
  useAgerStore,
} from '@agerpoint/utilities';

import { useCapturesViewerContext } from '../../captures-viewer';

interface IPotreeCloudTools {
  viewerController?: IPotreeViewerController;
}

export const PotreeCloudTools = ({ viewerController }: IPotreeCloudTools) => {
  const { annotations3dGeometry, annotations2dGeometry } =
    useCapturesViewerContext();
  const { isMobile } = useAgerStore();
  const [toggleAll3dAnnotations, setToggleAll3dAnnotations] = useState(true);

  const [, setSearch] = useSearchParams();

  const [annotations, setAnnotations] = useState<
    (IPoint3d | ILine3d | IMultiPoint3d | IPoint2d | IMultiPoint2d)[]
  >([]);

  useEffect(() => {
    const combinedAnnotations = [
      ...(annotations3dGeometry?.points ?? []),
      ...(annotations3dGeometry?.lines ?? []),
      ...(annotations3dGeometry?.polygons ?? []),
      ...(annotations3dGeometry?.multiPoints ?? []),
      ...(annotations2dGeometry?.points ?? []),
      ...(annotations2dGeometry?.multiPoints ?? []),
    ];

    setAnnotations(combinedAnnotations);
  }, [annotations3dGeometry, annotations2dGeometry]);

  const hasClassification = useMemo(
    () => (viewerController?.info?.classificationClasses?.length ?? 0) > 1,
    [viewerController?.info?.classificationClasses]
  );

  const isRGBA = useMemo(
    () =>
      viewerController?.info?.pointStyle === MaterialType.RGBA &&
      hasClassification,
    [viewerController?.info?.pointStyle, hasClassification]
  );

  const isClassified = useMemo(
    () =>
      viewerController?.info?.pointStyle === MaterialType.CLASSIFICATION &&
      hasClassification,
    [viewerController?.info?.pointStyle, hasClassification]
  );

  const captureJobImagesQuery = APIClient.useGetCaptureImagesByCaptureJobId(
    Number(viewerController?.info?.captureJobMetadata?.id),
    {
      query: {
        enabled: Number.isSafeInteger(
          Number(viewerController?.info?.captureJobMetadata?.id ?? undefined)
        ),
        queryKey: [
          APIUtils.QueryKey.captureJobs,
          {
            captureJobId: Number(
              viewerController?.info?.captureJobMetadata?.id
            ),
          },
          APIUtils.QueryKey.captureImages,
        ],
        select: (data) =>
          data?.filter((image) => image.x && image.y && image.z && image.id),
      },
    }
  );

  const hasImages = useMemo(
    () => (captureJobImagesQuery.data?.length ?? 0) > 0,
    [captureJobImagesQuery.data]
  );

  const [pointCloudLoaded, setPointCloudLoaded] = useState(false);

  useEffect(() => {
    setPointCloudLoaded(false);
  }, [viewerController?.info?.captureJobMetadata]);

  const pointCloudLoadedFn = useCallback(() => {
    setPointCloudLoaded(true);
  }, []);

  useActionListener(EffectNames.POTREE_POINT_CLOUD_LOADED, pointCloudLoadedFn);

  useEffect(() => {
    if (!pointCloudLoaded || !viewerController?.info?.viewerReady) {
      return;
    }

    viewerController?.removeCameraPositions();
    viewerController?.setCameraPositionsVisible?.(false);
    if (captureJobImagesQuery.data?.length) {
      viewerController?.loadCameraPositions?.(captureJobImagesQuery.data);
    }
  }, [
    pointCloudLoaded,
    viewerController?.info?.viewerReady,
    captureJobImagesQuery.data,
  ]);

  useEffect(() => {
    if (!pointCloudLoaded || !viewerController?.info?.viewerReady) {
      return;
    }

    const captureJob = viewerController?.info?.captureJobMetadata;
    if (!captureJob?.cameraSettings) {
      viewerController?.setCameraFrontView();
    } else {
      viewerController?.setCameraSettings(captureJob?.cameraSettings);
    }
  }, [
    pointCloudLoaded,
    viewerController?.info?.viewerReady,
    viewerController?.info?.captureJobMetadata,
  ]);

  useEffect(() => {
    if (!viewerController?.info?.viewerReady) {
      return;
    }

    if (viewerController?.info?.cameraPositionsVisible) {
      viewerController?.threeViewer?.showImageMarkers();
    } else {
      viewerController?.threeViewer?.hideImageMarkers();
    }
  }, [viewerController?.info?.cameraPositionsVisible]);

  useEffect(() => {
    // go through all annotation3d and see if they are all not visible
    const allNotVisible = annotations.every(
      (annotation) => !annotation.isVisible
    );
    if (allNotVisible) {
      setToggleAll3dAnnotations(!allNotVisible);
    } else {
      setToggleAll3dAnnotations(true);
    }
  }, [annotations, annotations3dGeometry, annotations2dGeometry]);

  return (
    <div
      className="absolute top-4 left-4 flex flex-row gap-2"
      style={{
        zIndex: 2,
      }}
    >
      <CloudButtonIconToggle
        isMobile={isMobile}
        disabled={!hasImages || !pointCloudLoaded}
        buttonId="camera-positions-toggle"
        leadingIcon="camera"
        tooltip="Toggle Camera Positions"
        tooltipPosition="bottom"
        isToggled={
          hasImages && (viewerController?.info.cameraPositionsVisible ?? false)
        }
        onToggle={() => {
          if (!hasImages && !pointCloudLoaded) {
            return;
          }
          viewerController?.setCameraPositionsVisible?.(
            !viewerController.info.cameraPositionsVisible
          );
        }}
        checkForAnnotations={false}
        annotations={annotations}
      />
      <CloudButtonIconToggle
        isMobile={isMobile}
        disabled={!pointCloudLoaded}
        buttonId="annotations-toggle"
        leadingIcon="location-dot"
        tooltip="Show Annotations"
        tooltipPosition="bottom"
        isToggled={toggleAll3dAnnotations}
        onToggle={(value) => {
          setToggleAll3dAnnotations(!toggleAll3dAnnotations);
          viewerController?.toggleAll3dAnnotations?.(!toggleAll3dAnnotations);
          if (!toggleAll3dAnnotations) {
            setSearch(
              (prev) => {
                prev.set('details', 'annotations');
                return prev;
              },
              {
                replace: true,
              }
            );
          }
        }}
        checkForAnnotations={true}
        annotations={annotations}
      />

      {hasClassification && (
        <div className="bg-white shadow-lg rounded-lg flex flex-row p-1 items-center">
          {isMobile ? (
            <>
              <CloudButton.Icon
                id="model-material-toggle-gaussian-splats"
                toggled={isRGBA}
                onClick={() => {
                  if (hasClassification) {
                    viewerController?.setPointStyle(MaterialType.RGBA);
                  }
                }}
                leadingIcon="image"
                tooltip="Photorealistic"
                tooltipPosition="bottom"
              />
              <CloudButton.Icon
                id="model-material-toggle-gaussian-points"
                toggled={isClassified}
                onClick={() => {
                  if (hasClassification) {
                    viewerController?.setPointStyle(
                      MaterialType.CLASSIFICATION
                    );
                  }
                }}
                // disabled={!gsModelLoaded}
                leadingIcon="swatchbook"
                tooltip="Classified"
                tooltipPosition="bottom"
              />
            </>
          ) : (
            <>
              <CloudButton.Icon
                id="model-material-toggle-gaussian-splats"
                toggled={isRGBA}
                onClick={() => {
                  if (hasClassification) {
                    viewerController?.setPointStyle(MaterialType.RGBA);
                  }
                }}
                leadingIcon="image"
                label="Photorealistic"
              />
              <CloudButton.Icon
                id="model-material-toggle-gaussian-points"
                toggled={isClassified}
                onClick={() => {
                  if (hasClassification) {
                    viewerController?.setPointStyle(
                      MaterialType.CLASSIFICATION
                    );
                  }
                }}
                leadingIcon="swatchbook"
                label="Classified"
              />
            </>
          )}
        </div>
      )}

      <div
        className={
          'flex flex-row bg-white rounded-lg px-1 gap-1 items-center shadow-lg'
        }
      >
        <CloudButton.Icon
          id="model-crop-button"
          onClick={async () => {
            await viewerController?.toggleCropEditingTool();
          }}
          leadingIcon="crop"
          tooltip="Crop"
        />
      </div>
    </div>
  );
};
