import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';

import {
  ILine3d,
  IMultiPoint2d,
  IMultiPoint3d,
  IPoint2d,
  IPoint3d,
} from '@agerpoint/types';

import { CloudButton } from '../button';
import { CloudInput } from '../input';

interface CloudButtonIconToggleProps {
  isMobile: boolean;
  disabled: boolean;
  annotations: (
    | IPoint3d
    | ILine3d
    | IMultiPoint3d
    | IPoint2d
    | IMultiPoint2d
  )[];
  checkForAnnotations: boolean;
  buttonId: string;
  leadingIcon: IconName | undefined;
  tooltip: string;
  tooltipPosition: 'top' | 'bottom' | 'left' | 'right';
  onToggle: (value?: boolean) => void;
  isToggled: boolean;
}

export const CloudButtonIconToggle: React.FC<CloudButtonIconToggleProps> = ({
  isMobile,
  disabled,
  annotations,
  checkForAnnotations = false,
  buttonId,
  leadingIcon,
  tooltip,
  tooltipPosition,
  isToggled,
  onToggle,
}) => {
  if (!annotations?.length && checkForAnnotations) {
    return null;
  }

  const handleToggle = () => {
    onToggle();
  };

  return (
    <div
      className={`bg-white shadow-lg rounded-lg flex flex-row p-1 items-center`}
    >
      {isMobile ? (
        <CloudButton.Icon
          id={buttonId}
          disabled={disabled}
          onClick={handleToggle}
          leadingIcon={leadingIcon}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
          toggled={isToggled}
        />
      ) : (
        <CloudInput.Toggle
          id={buttonId}
          disabled={disabled}
          value={isToggled}
          setValue={handleToggle}
          leadingIcon={leadingIcon}
          highlighted={isToggled}
          tooltip={tooltip}
          tooltipPosition={tooltipPosition}
        />
      )}
    </div>
  );
};
