import { Object3D, PerspectiveCamera, Scene, Vector3 } from 'three';

import {
  Annotation2dPoints,
  ColorsThreeD,
  EventBusNames,
  HexColor,
  IPoint2d,
  MarkerObjOptions,
  SVG_PATH_NAMES,
} from '@agerpoint/types';

import { GeometryBase } from '../geometry.base';
import { IconMarker } from './icon-marker';

export class Point2d extends GeometryBase implements IPoint2d {
  eventName: EventBusNames;
  point: Vector3;
  options: MarkerObjOptions;
  marker: IconMarker;
  userData: {
    originalColor: ColorsThreeD | HexColor;
    originalScale?: Vector3;
    originalLineWidth?: number;
  };
  type: Annotation2dPoints;

  constructor(
    eventName: EventBusNames,
    pointId: string,
    point: Vector3,
    options: MarkerObjOptions,
    scene: Scene,
    perspectiveCamera: PerspectiveCamera,
    canvas: HTMLCanvasElement,
    isPotree: boolean = false
  ) {
    super(scene, perspectiveCamera, false, canvas);
    if (!canvas || !this.canvas) {
      throw new Error('Canvas is required');
    }
    this.name = options.name || '';
    this.eventName = eventName;
    this.point = point;
    this.options = options;
    this.uniqueId = pointId;
    this.color = options.fill;
    this.perspectiveCamera = perspectiveCamera;
    this.userData = {
      originalColor: options.fill,
      originalScale: new Vector3(1, 1, 1),
    };
    this.type = Annotation2dPoints.AnnotationPoint2d;
    this.marker = new IconMarker(
      this.eventName,
      this.uniqueId,
      this.options,
      this.perspectiveCamera,
      this.canvas,
      isPotree,
      options?.pathType || SVG_PATH_NAMES.LOCATION_MARKER
    );
    this.marker.setPosition(this.point);
    this.marker.on('click', (id: string) => {
      this.emit('click', id);
    });
    this.marker.on('mousedown', (id: string) => {
      this.emit('mousedown', id);
    });
    this.marker.on('mouseup', (id: string) => {
      this.emit('mouseup', id);
    });
  }

  get isVisible() {
    return this.marker.element.style.display !== 'none';
  }

  render() {
    this.marker.updatePosition();
  }

  updatePosition(position: Vector3) {
    this.point = position;
    this.marker.setPosition(position);
    this.render();
  }

  updateType(type: Annotation2dPoints) {
    this.marker.updateType(type);
  }

  updateColor(color: ColorsThreeD | HexColor) {
    this.color = color;
    this.marker.updateColor(color);
    this.annoMgr.updateCapObjColor(this.uniqueId, color);
  }

  updateVisibility(isVisible: boolean) {
    this.visibility = isVisible;
    this.marker.updateVisibility(isVisible);
    this.notifyListeners();
  }

  updateName(name: string): void {
    this.annoMgr.updateCapObjName(this.uniqueId, name);
  }

  updateDescription(id: string, description: string): void {
    throw new Error('Method not implemented.');
  }

  zoomTo() {
    this.marker.zoomTo();
  }

  doZoom(object: Object3D): void {
    throw new Error('Method not implemented.');
  }

  dispose() {
    this.marker.dispose();
  }
  /**
   * persists deletion to the database
   */
  delete() {
    this.removePoint2dById(this.uniqueId);
    this.annoMgr.deleteCapObj(this.uniqueId);
  }

  disposeAndDelete(): void {
    this.dispose();
    this.delete();
    this.notifyListeners();
  }

  highlight(): void {
    this.marker.highlight();
    this.setSelectedObject(this.uniqueId, this.type);
  }

  unHighlight(): void {
    this.marker.unHighlight();
  }

  show(): void {
    this.marker.updateVisibility(true);
    this.notifyListeners();
  }
  hide(): void {
    this.marker.updateVisibility(false);
    this.notifyListeners();
  }
}
